.container nav {
  position: fixed;
  z-index: 1001;
  top: 0px;
  left: 0px;
  padding: 0px 15px;
  height: 60px;
  overflow: visible;
  display: flex;
  width: 100vw;
  background-color: rgb(35, 35, 41);
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 15px 0px;
}

.container nav .left-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.container nav .left-wrapper img {
  height: 24px;
}

.container nav .left-wrapper button {
  padding: 0px;
}

.container nav .left-wrapper button svg {
  color: rgb(187, 187, 194);
}

.container nav .right-wrapper {
  display: flex;
  align-items: center;
  width: max-content;
  flex-shrink: 0;
  gap: 8px;
  padding: 15px !important;
  height: 100%;
  cursor: pointer;
  transition: 0.2 all ease;
}

.container nav .right-wrapper:hover {
  background-color: rgb(56, 55, 63);
}

.container nav .right-wrapper .image-wrapper {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #cccccc;
}

.container nav .right-wrapper .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container nav .right-wrapper span {
  color: rgb(221, 221, 221);
  font-size: 13px;
  text-transform: capitalize;
  display: none;
}

.container nav .right-wrapper {
  padding: 0px;
  position: relative;
}

.container nav .right-wrapper svg {
  color: rgb(187, 187, 194);
}

.container nav .dropdown {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 15px 0px;
  position: absolute;
  z-index: 1000;
  top: 60px;
  background-color: rgb(35, 35, 41);
  width: 100%;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px;
  width: 200px;
  height: 0px;
  transition: 0.2s all linear;
  overflow: hidden;
}

.container nav .dropdown.active {
  height: 129.5px;
}

.container nav .dropdown a,
.container nav .dropdown button {
  padding: 0px;
  color: rgb(221, 221, 221);
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.container nav .dropdown a svg,
.container nav .dropdown button svg {
  color: rgb(96, 96, 123);
}

@media only screen and (min-width: 768px) {
  .container nav .right-wrapper span {
    display: block;
  }
}
