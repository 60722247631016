.container .sidebar {
  max-width: 240px;
  width: 100%;
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #232329;
  height: calc(-60px + 100vh);
  transition: 0.3s all ease;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  left: -240px;
  display: flex;
  width: 240px;
}

.container.active .sidebar {
  left: 0px;
}

.container .sidebar img {
  width: 150px;
  margin: 0px auto;
  margin-bottom: 50px;
}

.container .sidebar .navlink {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 11px 20px;
  transition: 0.2s all ease;
  height: 32px;
  cursor: pointer;
  position: relative;
}

.container .sidebar .navlink:hover {
  background-color: rgb(56, 55, 63);
}

.container .sidebar .navlink span {
  color: rgb(221, 221, 221);
  font-weight: 500;
  white-space: nowrap;
  line-height: 16px;
  font-size: 14px;
}

.container .sidebar .navlink svg {
  color: #999999;
}

.container .sidebar .navlink.active span,
.container .sidebar .navlink.active svg {
  color: #eacc76;
}

.container .sidebar .navlink.active::before {
  width: 2px;
  height: 100%;
  content: "";
  position: absolute;
  background-color: #eacc76;
  height: 32px;
  left: 0px;
}
