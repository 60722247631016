html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  box-sizing: border-box;
  transition: 0.2ms all ease-out;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
a {
  text-decoration: none;
  color: #171717;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  border: 0px;
  width: max-content;
  height: max-content;
}

select {
  text-indent: 1px;
  text-overflow: "";
}

body::-webkit-scrollbar {
  background-color: #999999;
  width: 6px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #2a2a31;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #eacc76;
  border-radius: 6px;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

body .table-wrapper .table-container::-webkit-scrollbar {
  background-color: #2a2a31;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
body .table-wrapper .table-container::-webkit-scrollbar-track {
  background-color: #2a2a31;
}

/* scrollbar itself */
body .table-wrapper .table-container::-webkit-scrollbar-thumb {
  background-color: #606071;
  border-radius: 16px;
}

body .table-wrapper .table-container::-webkit-scrollbar-thumb:hover {
  background-color: #eacc76;
}

/* set button(top and bottom of the scrollbar) */
body .table-wrapper .table-container::-webkit-scrollbar-button {
  display: none;
}

input,
input:focus,
select,
select:focus,
textarea:focus {
  border: 0px;
  outline: none;
  transition: color 0.2s ease;
  font-weight: 500;
  color: rgb(221, 221, 221);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: #999999;
  font-weight: 400;
}

.container {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  overflow-x: hidden;
}

button,
a.button {
  font-weight: 500;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 5px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  cursor: pointer;
}

button {
  color: #dddddd;
}

.input-wrapper p,
.input-wrapper-image p {
  color: #f39c12;
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 400;
  width: 100%;
  text-align: right;
}

.body-wrapper {
  padding-top: 0px;
  background-color: rgb(42, 42, 49);
  width: 100vw;
  min-height: 100vh;
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-left: 0px;
  width: 100vw;
  margin-top: 75px;
  padding: 15px 15px 15px 25px;
  transition: 0.3s all ease;
}

.section h1 {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
  width: 100%;
  font-weight: 500;
  padding-left: 8px;
  color: rgb(221, 221, 221);
}

.container.active .section {
  margin-left: 240px;
  width: calc(100vw - 240px);
}

@media only screen and (max-width: 1023px) {
  .body-wrapper {
    padding-top: 56px;
    width: 100vw;
  }

  .section {
    margin-top: 15px;
    padding: 15px;
  }

  .container.active .section {
    width: 100vw;
    margin-left: 0px;
  }
}

.section .wrapper {
  background-color: rgb(35, 35, 41);
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-wrapper .table-container {
  width: 100%;
  overflow-x: scroll;
}

.table-header {
  display: flex;
  gap: 24px 40px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

.table-header.flex-start {
  justify-content: flex-start;
}

.table-header .search {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.table-header .search .input-wrapper {
  position: relative;
}

.table-header .search .input-wrapper svg {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  color: #eacc76;
}

.table-header .search .input-wrapper input,
.table-header .input-wrapper select {
  padding: 10px 15px;
  height: max-content;
  font-size: 14px;
  width: 200px;
}

.table-header .input-wrapper select {
  width: auto;
}

.input-wrapper select option {
  background-color: #fff; 
  color: #333; 
}

.table-header h1 {
  font-weight: 700;
  font-size: 24px;
  color: #8e9195;
}

table {
  width: 100%;
}

table th,
table td {
  text-align: left;
  padding: 10px;
  min-height: 50px;
  vertical-align: middle;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 14px;
  flex: 1;
  line-height: 20px;
  border-bottom: 2px solid rgb(51, 50, 70);
  color: rgb(221, 221, 221);
  min-width: 50px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

table tr {
  border-bottom: 2px solid rgb(51, 50, 70);
}

table td.images {
  max-width: 100%;
  /* display: inline-block; */
  align-items: center;
  gap: 10px;
  flex-direction: row;
}

table td.images img {
  margin-right: 10px;
}

table tbody tr:last-child td {
  border: 0px;
}

table td a {
  color: #438bca;
  text-decoration: underline;
}

table td img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 2px 2px 5px #171717;
}

table td.rect-image img {
  width: 80px;
  height: 100px;
}

table th {
  font-size: 13px;
  color: #eacc76;
}

table td .flex {
  display: flex;
  width: max-content;
  align-items: center;
  gap: 8px;
}

table td .table-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  height: 100%;
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 40px;
}

.table-wrapper.see-matches .table {
  height: 0px;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
}

.table-wrapper.see-matches .table.active {
  height: 450px;
}

.table-wrapper.see-matches .table-header {
  justify-content: space-between;
}

button.danger {
  background-color: rgba(150, 13, 13, 0.714);
}

button.add {
  background-color: #9ec065;
  color: #171717;
}

button.primary {
  color: #171717;
  background: linear-gradient(
    90deg,
    #ffdd9d 0%,
    #7e5b33 7.2%,
    #a37e56 16.61%,
    #ffdd9d 24.61%,
    #fff2cd 37.63%,
    #ffdd9d 46.1%,
    #e7bf88 58.18%,
    #7e5b33 63.98%,
    #a37e56 70.89%,
    #e7bf88 79.51%,
    #fff2cd 90.49%,
    #a37e56 100%
  );
}

button.info,
label.info {
  background-color: #346598;
}

button.success,
a.button.success {
  background-color: #348843c1;
}

button.secondary {
  background-color: #228ccf;
}

button.secondary.disabled,
button.primary.disabled {
  background-color: #c9c8c8;
}

button.transparent {
  background-color: transparent;
  color: #171717;
  box-shadow: none;
  padding: 0px;
}

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1004;
  display: none;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-in;
}

.login-container {
  width: 100vw;
  overflow: hidden;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(42, 42, 49);
  background-repeat: no-repeat;
  background-size: cover;
}

.modal-container .modal-opener {
  padding-bottom: 20px;
}

.modal-container .modal-opener button {
  margin-left: auto;
}

.modal.active {
  display: flex;
}

.modal .wrapper,
.login-wrapper {
  width: calc(100vw - 20px);
  padding: 35px 30px;
  overflow-y: auto;
  max-width: 520px;
  background-color: rgb(35, 35, 41);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border: 0px solid rgba(0, 0, 0, 0.175);
  position: relative;
}

.modal .wrapper {
  max-width: 740px;
  padding: 10px 15px 30px 15px;
  height: max-content;
  max-height: calc(100vh - 100px);
  width: calc(100vw - 40px);
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media only screen and (min-width: 768px) {
  .login-wrapper {
    padding: 50px 60px;
  }
  .modal .wrapper {
    max-width: 740px;
    padding: 10px 30px 30px 30px;
  }
}

.modal .wrapper.full {
  max-width: 1600px;
}

.modal .wrapper .header {
  background-color: transparent;
  width: 100%;
  max-width: 740px;
  position: relative;
  padding: 10px 0px 15px 0px;
}

.login-wrapper .header {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22.75px 20px 22.75px;
}

.login-wrapper .header .logo img {
  width: 200px;
}

.modal .wrapper.full .header {
  max-width: 740px;
}

.modal .wrapper .header h2 {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  color: rgb(221, 221, 221);
}

.modal .wrapper .header .close {
  padding: 0px;
  position: absolute;
  right: -15px;
  top: 5px;
}

.modal .wrapper .header .close svg {
  color: rgb(96, 95, 123);
}

.modal .wrapper .content,
.login-wrapper .content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal .wrapper .content {
  padding: 20px;
  padding-bottom: 60px;
}

.modal .wrapper .content .lists {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal .wrapper .content .lists li {
  color: #212121;
  font-size: 14px;
}

.modal .wrapper .content .form-section h4 {
  font-weight: 600;
  color: #8e9195;
  font-size: 20px;
  padding-bottom: 14px;
}

.modal .wrapper .content .form-section.list h4 {
  padding-bottom: 0px;
}

.modal .wrapper .content .form-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .modal .wrapper .content .for */

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-wrapper .icon-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.input-wrapper .icon-wrapper .icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #33333a;
}

.input-wrapper .icon-wrapper .icon.active {
  background-color: #eacc76;
}

.input-wrapper .icon-wrapper .icon svg {
  color: #999999;
}

.input-wrapper .icon-wrapper .icon.active svg {
  color: #ffffff;
}

.input-wrapper.button {
  flex-direction: row;
}

.input-wrapper span {
  font-size: 16px;
  font-weight: 600;
  color: #8e9195;
}

.flexed {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px 40px;
}

@media only screen and (min-width: 768px) {
  .flexed {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.m-r {
  margin: 24px 0;
}

.input-wrapper .checkboxes,
.form-section .checkboxes {
  display: flex;
  align-items: center;
  gap: 10px 40px;
  flex-wrap: wrap;
}

.input-wrapper .checkboxes input,
.form-section .checkboxes input {
  height: 13px;
  width: 13px;
}

.input-wrapper .checkboxes input[type="number"] {
  width: 40px;
}

.checkbox-wrapper {
  display: flex;
  gap: 2px;
  align-items: center;
}

.input-wrapper label,
.checkbox-wrapper label {
  color: rgb(221, 221, 221);
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.input-wrapper-image {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.input-wrapper-image input {
  display: none;
}

.input-wrapper-image .image {
  width: 100%;
  aspect-ratio: 5/3;
  overflow: hidden;
  border: 1px solid rgb(51, 51, 58);
  padding: 2px;
}

.input-wrapper-image .video {
  max-width: 250px;
  aspect-ratio: 4/5;
  width: 100%;
  overflow: hidden;
  border: 1px solid #c9c8c8;
  padding: 2px;
  flex-wrap: wrap;
  position: relative;
}

.input-wrapper-image .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-wrapper-image .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-wrapper label .required {
  color: #f1416c;
}

.input-wrapper input,
.input-wrapper-image label,
.input-wrapper select,
.input-wrapper textarea {
  background-color: transparent;
  color: rgb(221, 221, 221);
  font-size: 12px;
  padding: 5px 10px;
  border: 1px solid rgb(51, 51, 58);
  height: 32px;
  box-sizing: border-box;
  transition: 0.2s all ease;
  width: 100%;
}

.input-wrapper textarea {
  height: auto;
  height: 300px;
}

.input-wrapper-image label {
  font-weight: 500;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  cursor: pointer;
  background-color: #eacc76;
  border: 0px;
  color: #171717;
}

.input-wrapper input:focus,
.input-wrapper select:focus,
.input-wrapper textarea:focus {
  border-color: #eacc76;
}

.form-section ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form-section ul li {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 10px 10px 10px;
  border-radius: 3px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  position: relative;
}

.form-section ul li button.danger {
  padding: 5px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.form-section button.secondary {
  margin-left: auto;
  display: block;
  margin-top: 5px;
}

.modal .wrapper .footer,
.login-wrapper .footer {
  /* position: sticky; */
  bottom: 0px;
  z-index: 1000;
}

.login-wrapper .footer {
  background-color: transparent;
  padding-top: 20px;
}

.login-wrapper .footer button {
  padding: 10px 75px;
  margin: 0px auto;
}

.modal .wrapper .footer button {
  font-size: 14px;
  padding: 10px 30px;
  margin: 0px auto;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  gap: 20px 50px;
  flex-wrap: wrap;
}

.filters .input-wrapper {
  max-width: 300px;
  width: 100%;
}
.filters .filters .input-wrapper select {
  border: solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-width: 2px;
}

.filters .input-wrapper select:focus {
  border-color: #f1b429;
}

.loader-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  background-color: rgb(42, 42, 49);
  z-index: 9999999;
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #eacc76;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.pagination {
  display: flex;
  gap: 1px;
  align-items: center;
}

.pagination .pages {
  display: flex;
  gap: 1px;
  align-items: center;
}

.pagination button {
  padding: 0px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgb(51, 51, 58);
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #999999;
}
.pagination button.number {
  background-color: transparent;
}

.pagination button.active:hover {
  background-color: #eacc76;
  color: #171717;
}

.pagination button.active:hover svg {
  color: rgb(221, 221, 221);
}

.pagination button.number:hover {
  color: #eacc76;
}

.pagination button.number:hover {
  background-color: transparent;
}

.pagination button.number.active,
.pagination button.number.active:hover {
  color: rgb(221, 221, 221);
}

.pagination button svg {
  color: #515155;
}

.pagination button.active svg {
  color: rgb(153, 153, 153);
}

.pagination p {
  margin-left: 10px;
  font-size: 12px;
  color: rgb(153, 153, 153);
}


.hover-caption {
  position: relative;
  cursor: pointer;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: 100%; /* Above the cell */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

.hover-caption:hover .tooltip {
  display: block;
}

.delete-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

.popup-content {
  background: #ffffff; /* White background for the popup */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.popup-content h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.popup-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.popup-actions button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-actions .success {
  background-color: #4caf50;
  color: white;
}

.popup-actions .success:hover {
  background-color: #45a049;
}

.popup-actions .danger {
  background-color: #f44336;
  color: white;
}

.popup-actions .danger:hover {
  background-color: #d32f2f;
}

/* Fade-in animation for the popup */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
