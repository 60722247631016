.settings-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.section .settings-wrapper .wrapper {
  padding: 40px;
}

.settings-wrapper .inputs {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 300px;
  max-width: 760px;
  min-height: 340px;
  align-items: center;
}

.settings-wrapper .inputs button {
  margin-top: 2px;
}

.settings-wrapper .inputs .input-wrapper {
  width: 100%;
  gap: 2px;
}

.settings-wrapper .inputs .input-wrapper label {
  margin-bottom: 10px;
}

.settings-wrapper .inputs .input-wrapper input,
.settings-wrapper .inputs .input-wrapper .react-select__control {
  border: 0px;
  padding: 5px 0px;
  height: auto !important;
  border-bottom: 1px solid #33333a;
}

.settings-wrapper .inputs .input-wrapper input:focus,
.settings-wrapper .inputs .input-wrapper .react-select__control:focus {
  border-bottom: 2px solid #eacc76;
}

.settings-wrapper .inputs .input-wrapper .jEHVHv {
  padding: 0px;
  height: auto !important;
  border: 0px;
}

.settings-wrapper .inputs .input-wrapper p {
  margin-top: 4px;
}

.settings-wrapper
  .inputs
  .input-wrapper
  .jEHVHv
  .react-select__value-container
  > div {
  margin: 0px;
}

.settings-wrapper
  .inputs
  .input-wrapper
  .jEHVHv
  .react-select__value-container {
  padding: 0px;
}

.settings-wrapper .inputs .input-wrapper .css-1xc3v61-indicatorContainer {
  padding: 5px;
}

.settings-wrapper .image {
  height: max-content;
  width: max-content !important;
}

.settings-wrapper .image label div,
.settings-wrapper .image label {
  width: 250px !important;
  height: 250px !important;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.settings-wrapper .image label div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.settings-wrapper .image input {
  display: none;
}

@media only screen and (min-width: 768px) {
  .settings-wrapper {
    flex-wrap: nowrap;
    flex-direction: row !important;
  }

  .settings-wrapper .image label div,
  .settings-wrapper .image label {
    width: 290px !important;
    height: 290px !important;
  }
}
