.settings-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.section .settings-wrapper .wrapper {
  padding: 40px;
}

.settings-wrapper .inputs {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 300px;
  max-width: 760px;
  min-height: 340px;
  align-items: center;
}

.settings-wrapper .inputs button {
  margin-top: 2px;
}

.settings-wrapper .inputs .input-wrapper {
  width: 100%;
  gap: 2px;
  position: relative;
}

.settings-wrapper .inputs .input-wrapper label {
  margin-bottom: 10px;
}

.settings-wrapper .inputs .input-wrapper .icon {
  position: absolute;
  top: 27px;
  right: 10px;
  cursor: pointer;
}

.settings-wrapper .inputs .input-wrapper p {
  margin-top: 4px;
}

.settings-wrapper .inputs .input-wrapper .icon svg {
  color: rgb(221, 221, 221);
}

.settings-wrapper .inputs .input-wrapper .icon.active svg {
  color: #eacc76;
}

.settings-wrapper .inputs .input-wrapper input {
  border: 0px;
  padding: 5px 0px;
  height: auto !important;
  border-bottom: 1px solid #33333a;
}

.settings-wrapper .inputs .input-wrapper input:focus {
  border-bottom: 2px solid #eacc76;
}
