.salon-images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: max-content !important;
  max-width: max-content !important;
}

.salon-images .image {
  position: relative;
  width: 100px;
  height: 75px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #171717;
  position: relative;
}

.salon-images .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.salon-images .image button {
  position: absolute;
  display: none;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.salon-images .image:hover button {
  display: flex;
}
