.dashboard.section .cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.dashboard .wrapper .card {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
}

.dashboard .wrapper .card .chart {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  overflow: visible;
}

.dashboard .wrapper {
  height: 100%;
  max-width: 400px;
}

.dashboard .wrapper .card .chart ul {
  display: flex;
  gap: 4px;
  align-items: flex-end;
}

.dashboard .wrapper .card .chart ul {
  overflow-x: auto;
  overflow-y: visible;
  height: 200px;
  max-width: 500px;
}

.dashboard .wrapper .card .chart ul li {
  height: 100%;
  overflow: visible;
}

.dashboard .wrapper .card .chart ul li p {
  position: absolute;
  z-index: 3;
  display: none;
  bottom: 10px;
  white-space: nowrap;
  font-size: 8px;
  font-weight: 700;
  padding: 4px 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.dashboard .wrapper .card .chart ul li:hover p {
  /* display: block; */
}

.dashboard .wrapper .card .chart ul::-webkit-scrollbar {
  display: none;
}

.dashboard .wrapper .card .chart ul li {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.dashboard .wrapper .card .chart ul li span {
  font-size: 9px;
  font-weight: 500;
  color: #dddddd;
  position: absolute;
  transform: rotateZ(-90deg);
  bottom: 10px;
  padding-left: 0px;
}

.dashboard .wrapper .card .chart span {
  color: #dddddd;
  font-size: 20px;
  font-weight: 900;
}

.dashboard .wrapper .card .chart ul li div {
  width: 20px;
  height: 100%;
}

.dashboard .wrapper .card .chart.users ul li div {
  background-color: rgb(255, 72, 97);
}

.dashboard .wrapper .card .chart.orders ul li div {
  background-color: rgb(112, 187, 253);
}

.dashboard .wrapper .card .chart.appointments ul li div {
  background-color: rgb(200, 143, 250);
}

.dashboard .wrapper .card .chart ul li:first-child div {
  background-color: rgb(76, 225, 182);
}

.dashboard .wrapper .card h3 {
  font-size: 13px;
  color: #dddddd;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .section.dashboard .wrapper {
    padding: 40px !important;
    max-width: calc(50% - 20px);
  }

  .dashboard .wrapper .card .chart ul li span {
    font-size: 10px;
  }
}
